<template>
  <div>
    <div v-if="$route.params.id" class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder h2back m-0">
        <span
          v-if="$route.params.id"
          @click="$router.go(-1)"
        >
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        <span> {{ parent.translations.name[currentLanguage] }}</span>
      </h2>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                {{ $t('Productos') }}
              </h3>
            </b-col>
            <b-col cols="auto">
              <template v-if="createActionVisible">
                <b-link :to="{ name: 'createProduct' }">
                  <b-button
                    variant="primary"
                    class="mr-1 text-nowrap"
                  >
                    {{ '+ ' + $t('Añadir nuevo producto') }}
                  </b-button>
                </b-link>
              </template>
            </b-col>
          </b-row>
          <hr>
          <b-row
            v-if="$route.params.id"
            align-h="between"
            align-v="center"
            no-gutters
            class="px-2 pt-1 pb-2"
          >
            <b-col>
              <b-row align-h="end">
                <b-col cols="5">
                  <div class="d-flex justify-content-end align-items-center">
                    <b-form-input
                      v-model="searchTerm"
                      :placeholder="$t('Busqueda')"
                      type="text"
                      class="d-inline-block w-auto"
                      @keyup.enter="handleSearch"
                    />
                    <b-button
                      class="ml-1"
                      variant="primary"
                      @click="handleSearch"
                    >
                      {{ $t("Buscar") }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            no-gutters
            class="py-2"
          >
            <b-col
              v-for="cat in items"
              :key="cat.id"
              xs="12"
              sm="6"
              lg="4"
              class="mb-3"
            >
              <CategoryCard
                class="px-2 py-1"
                :cat="cat"
              />
            </b-col>
            <b-col cols="12">
              <b-pagination
                  v-if="totalItems > perPage"
                v-model="page"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                align="center"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BPagination, BFormInput, BButton, BCard, BCol, BRow, BLink,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import CategoryCard from '@/components/product-categories/card/CategoryCard.vue'

export default {
  components: {
    CategoryCard,
    BCard,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
  },
  data() {
    return {
      page: 1,
      perPage: 6,
      parameters: {},
      log: [],
      dir: false,
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      items: 'categories/getItems',
      totalItems: 'categories/getTotalItems',
      currentUser: 'auth/getUser',
      parent: 'categories/getCategory',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    id_categoria() {
      return this.$route.params.id
    },
    createActionVisible() {
      return !['user', 'documental', 'admin'].includes(this.currentUser.roles[0].name)
    },
  },
  watch: {
    id_categoria() {
      this.chargeData()
    },
  },
  created() {
    this.chargeData()
  },
  methods: {
    ...mapActions({
      list: 'categories/getListCategories',
      delete: 'categories/delete',
      getCategory: 'categories/getCategory',
    }),
    chargeData() {
      let parent = 0
      if (this.$route.params.id) {
        parent = this.$route.params.id
        this.getCategory(parent)
      }
      this.list({
        page: this.currentPage, per_page: this.pageLength, search: this.searchTerm, parent,
      })
      this.searchTerm = ''
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteCategory(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>
