<template>
  <b-card
    no-body
    class="cursor-pointer m-0 fill-height"
    @click="routeClick()"
  >
    <b-row
      class="flex-column fill-height"
      align-h="between"
    >
      <b-col cols="auto">
        <img
          v-if="cat.image && cat.image.length > 0"
          class="cat-image"
          :alt="cat.name"
          :src="cat.image"
          @error="$event.target.src = defaultImage"
        >
        <img
          v-else
          :alt="cat.name"
          :src="defaultImage"
          class="cat-image"
        >
        <div class="font-weight-bold font-medium-2 text-dark pt-1">
          {{ cat.name }}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import DefaultImage from '@/assets/images/pages/default-placeholder.png'

export default {
  name: 'CategoryCard',
  props: {
    cat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultImage: DefaultImage,
    }
  },
  methods: {
    routeClick() {
      if (this.$route.params.id) {
        this.$router.push({ name: 'products', params: { category_id: this.cat.id } })
      } else {
        this.$router.push({ name: 'categoriesList', params: { id: this.cat.id } })
      }
    },
  },
}
</script>

<style scoped>
.cat-image {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
</style>
